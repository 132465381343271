<template>
  <div class="card-list">
    <div class="display-normal-none">
      <div
        class="card-item"
      >
        <div class="card__title">
          Размер взноса в компенсационный фонд возмещения вреда:
        </div>
        <div
          class="card__value"
          v-if="compensationFundFeeVv"
        >
          {{ formatPrice(compensationFundFeeVv) }} рублей
        </div>
        <div
          class="card__value"
          v-else
        >-</div>

        <div class="card__title">
          Размер взноса в компенсационный фонд обеспечения договорных обязательств:
        </div>
        <div
          class="card__value"
          v-if="compensationFundFeeOdo"
        >
          {{ formatPrice(compensationFundFeeOdo) }} рублей
        </div>
        <div
          class="card__value"
          v-else
        >
          -
        </div>
      </div>
    </div>

    <table class="display-mobile-none">
      <tr>
        <!--        <th @click="sort('sro_registration_number')">-->
        <td
            class="border-t px-10"
        >
          <div class="card__title">
            Размер взноса в компенсационный фонд возмещения вреда:
            <span
              class="card__value"
              v-if="compensationFundFeeVv"
            >
              {{ formatPrice(compensationFundFeeVv) }} рублей
            </span>
            <span
              class="card__value"
              v-else
            >-</span>
          </div>
        </td>
      </tr>
      <tr>
        <!--        <th @click="sort('sro_full_description')">-->
        <td
          class="px-10 border-tb"
        >
          <div class="card__title">
            Размер взноса в компенсационный фонд обеспечения договорных обязательств:
            <span
              class="card__value"
              v-if="compensationFundFeeOdo"
            >
              {{ formatPrice(compensationFundFeeOdo) }} рублей
            </span>
            <span
              class="card__value"
              v-else
            >-</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { formatPrice } from '../../helpers/format'

export default {
  name: 'MemberFundsInfo',
  props: {
    compensationFundFeeOdo: {
      type: Number,
      default: 0
    },
    compensationFundFeeVv: {
      type: Number,
      default: 0
    }
  },
  methods: {
    formatPrice
  }
}
</script>

<style scoped>

</style>
