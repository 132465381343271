<template>
  <div class="card-item">
    <warn-message
      v-if="member.sro.deactivate_message"
      style="font-size: 1rem"
    >Исключены сведения о СРО из государственного реестра СРО {{ member.sro.deactivate_message }}</warn-message>
    <div class="d-flex flex-wrap">

      <div class="card-item__title">
        СРО:
      </div>
      <div class="card-item__value">
<!--        <div v-if="member.sro.deactivate_message" style="color: #ff5722">-->
<!--        <v-icon size="16px" color="#ff5722">mdi-alert</v-icon>-->
<!--        Исключены сведения о СРО из государственного реестра СРО {{ member.sro.deactivate_message }}-->
<!--      </div>-->
        {{ member.sro.full_description }}
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        Полное наименование:
      </div>
      <div class="card-item__value">
        {{ member.full_description }}
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        Сокращенное наименование:
      </div>
      <div class="card-item__value">
        {{ member.short_description }}
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        Регистрационный номер члена саморегулируемой организации и дата его регистрации в реестре членов
        саморегулируемой организации:
      </div>
      <div class="card-item__value">
        {{
          member.registration_number + (formatDate(member.registry_registration_date) ? `, ${formatDate(member.registry_registration_date)}` : '')
        }}
      </div>
    </div>

    <div class="d-flex flex-wrap"
         v-if="member.suspension_date"
    >
      <div
        class="card-item__title"
      >
        Дата прекращения членства:
      </div>
      <div
        class="card-item__value"
      >
        {{ formatDate(member.suspension_date) }}
      </div>
    </div>

    <div class="d-flex flex-wrap"
         v-if="member.suspension_reason"
    >
      <div class="card-item__title">
        Основание прекращения членства:
      </div>
      <div class="card-item__value">
           {{ member.suspension_reason }}
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        ОГРН/ОГРНИП:
      </div>
      <div class="card-item__value">
        {{ member.ogrnip }}
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        ИНН
      </div>
      <div class="card-item__value">
        {{ member.inn }}
      </div>
    </div>

    <div
      v-if="member.state_registration_date"
      class="d-flex flex-wrap">
      <div class="card-item__title">
        Дата государственной регистрации
      </div>
      <div class="card-item__value">
        {{ formatDate(member.state_registration_date) }}
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        Номер контактного телефона:
      </div>
      <div class="card-item__value">
        {{ member.phones }}
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        Регион:
      </div>
      <div class="card-item__value">
        {{ getRegion(member) }}
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="card-item__title" v-if="member.member_type.id === 1">
        Место нахождения юридического лица:
      </div>
      <div class="card-item__title" v-if="member.member_type.id === 2">
        Место фактического осуществления деятельности:
      </div>
      <div class="card-item__value">
        {{ getAddress() }}
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        ФИО, осуществляющего функции единоличного
        исполнительного органа юридического лица и (или)
        руководителя коллегиального исполнительного органа
        юридического лица:
      </div>
      <div class="card-item__value">
        {{ member.director }}
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        Сведения о соответствии члена СРО условиям членства
        в СРО, предусмотренным законодательством РФ и (или)
        внутренними документами СРО:
      </div>
      <div class="card-item__value">
        {{ member.accordance_status.title }}
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        Иные сведения:
      </div>
      <div class="card-item__value">
        {{ member.other_information }}
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        Дата размещения информации:
      </div>
      <div class="card-item__value">
        {{ member.registry_registration_date_time_string }}
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div class="card-item__title">
        Дата изменения информации:
      </div>
      <div class="card-item__value">
        {{ member.last_updated_at_date_time_string }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, formatDateT } from '../../helpers/format'
import WarnMessage from '../alerts/WarnMessage'

export default {
  name: 'MemberCardInfo',
  components: { WarnMessage },
  props: {
    member: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate (datetime) {
      if (!datetime) {
        return ''
      }
      return formatDate(new Date(datetime))
    },
    formatDateT (datetime) {
      if (!datetime) {
        return ''
      }
      return formatDateT(new Date(datetime))
    },
    getRegion (member) {
      if (member.region_number) {
        return member.region_number.title
      } else if (member.ip_region_number) {
        return member.ip_region_number.title
      } else {
        return '-'
      }
    },
    getAddress () {
      let address = this.member.index ? `${this.member.index}, ` : ''
      address += this.member.country ? `${this.member.country} ` : ''

      if (this.member.region_number) {
        const cityRegexp = /(Москва|Санкт|Петербург|Севастополь)/mui
        address += `${this.member.region_number.title} `

        address += this.member.district ? `${this.member.district} ` : ''
        address += this.member.vtgmo ? `${this.member.vtgmo} ` : ''

        const m1 = cityRegexp.test(this.member.locality)
        if (this.member.locality && !m1) {
          address += `${this.member.locality} `
        }
      } else {
        address += this.member.district ? `${this.member.district} ` : ''
        address += this.member.vtgmo ? `${this.member.vtgmo} ` : ''
        address += this.member.locality ? `${this.member.locality} ` : ''
      }

      address += this.member.street ? `${this.member.street} ` : ''
      address += this.member.house ? `${this.member.house} ` : ''
      address += this.member.building ? `${this.member.building} ` : ''
      address += this.member.room ? `${this.member.room} ` : ''

      return address
    }
  }
}
</script>

<style scoped>

</style>
