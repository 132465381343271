<template>
  <div class="card-list">
    <div
      class="card__title"
      style="text-align: center"
    >
      Сведения о наличии у члена саморегулируемой организации права выполнения работ по
      строительству, реконструкции, капитальному ремонту, сносу объектов капитального строительства по
      договору строительного подряда, по договору подряда на осуществление сноса
    </div>
    <div class="card"
         v-if="right"
    >
      <div
        class="card-item"
      >
        <div class="card__title">
          Статус права:
        </div>
        <div class="card__value">
          {{ right.right_status.title }}
        </div>
        <div class="card__title">
          Основание наделения правом:
        </div>
        <div class="card__value">
          {{ right.basis }}
        </div>

        <div class="card__title">
          В отношении объектов капитального строительства (кроме особо опасных, технически сложных и уникальных
          объектов,
          объектов использования атомной энергии):
        </div>
        <div class="card__value">
          {{ right.is_simple ? 'Да' : 'Нет' }}
        </div>

        <div class="card__title">
          В отношении особо опасных, технически сложных и уникальных объектов капитального строительства
          (кроме объектов использования атомной энергии):
        </div>
        <div class="card__value">
          {{ right.is_extremely_dangerous ? 'Да' : 'Нет' }}
        </div>

        <div class="card__title">
          В отношении объектов использования атомной энергии:
        </div>
        <div class="card__value">
          {{ right.is_nuclear ? 'Да' : 'Нет' }}
        </div>

        <div class="card__title">
          Стоимость работ по одному договору подряда (уровень ответственности):
        </div>
        <div
          class="card__value"
        >
          {{ getResponsibilityLevelVV(memberResponsibilityLevelVv) }}
        </div>

        <div class="card__title">
          Размер обязательств по договорам подряда с использованием конкурентных способов заключения договоров
          (уровень ответственности):
        </div>
        <div
          class="card__value"
          v-if="memberResponsibilityLevelOdo"
        >
          {{ memberResponsibilityLevelOdo.title }}, {{ memberResponsibilityLevelOdo.cost }}
        </div>
        <div
          class="card__value"
          v-else
        >-
        </div>

        <div class="card__title">
          Сведения об ограничении права принимать участие в заключении договоров строительного подряда,
          договоров подряда на осуществление сноса объектов капитального строительства с использованием
          конкурентных способов заключения договоров:
        </div>
        <div
          class="card__value"
        >
          {{ getRightRestrictInfo(right) }}
        </div>

        <div class="card__title">
          Дата уплаты взноса (дополнительного взноса):
        </div>
        <div
          class="card__value"
          v-if="right.compensation_fund_fee_odopayment_date"
        >
          {{ formatDateShort(new Date(right.compensation_fund_fee_odopayment_date)) }}
        </div>
        <div
          class="card__value"
          v-else
        >-
        </div>
      </div>
    </div>
    <table class="table__list"
           v-if="right"
    >
      <tr>
        <th style="width: 130px; cursor: default">
          <div>
            <!--            <sort-icon :sort="options.sortBy.registry_registration_date"/>-->
            <div>
              Статус права
            </div>
          </div>
        </th>
        <!--        <th @click="sort('sro_full_description')">-->
        <th style="cursor: default;">
          <div>
            <!--            <sort-icon :sort="options.sortBy.sro_full_description"/>-->
            <div>
              Основание наделения правом
            </div>
          </div>
        </th>
        <!--        <th @click="sort('member_status')">-->
        <th style="cursor: default;">
          <!--          <sort-icon :sort="options.sortBy.member_status"/>-->
          <div>
            В отношении объектов капитального строительства
            (кроме особо опасных, технически сложных и уникальных объектов, объектов использования атомной энергии)
          </div>
        </th>

        <!--        <th @click="sort('full_description')">-->
        <th style="cursor: default;">
          <div>
            <!--            <sort-icon :sort="options.sortBy.full_description"/>-->
            <div>
              В отношении особо опасных, технически сложных и уникальных объектов капитального строительства
              (кроме объектов использования атомной энергии)
            </div>
          </div>
        </th>

        <!--        <th @click="sort('inn')">-->
        <th style="min-width: 100px; cursor: default;">
          <div>
            <!--            <sort-icon :sort="options.sortBy.inn"/>-->
            <div>
              В отношении объектов использования атомной энергии
            </div>
          </div>
        </th>

        <!--        <th @click="sort('ogrnip')">-->
        <th style="cursor: default;">
          <div>
            <!--            <sort-icon :sort="options.sortBy.ogrnip"/>-->
            <div>
              Стоимость работ по одному договору подряда (уровень ответственности)
            </div>
          </div>
        </th>

        <!--        <th @click="sort('registry_registration_date')">-->
        <th style="width: 130px; cursor: default;">
          <div>
            <!--            <sort-icon :sort="options.sortBy.registry_registration_date"/>-->
            <div>
              Размер обязательств по договорам подряда с использованием конкурентных способов заключения договоров
              (уровень ответственности)
            </div>
          </div>
        </th>
        <th style="width: 130px; cursor: default;">
          <div>
            <!--            <sort-icon :sort="options.sortBy.registry_registration_date"/>-->
            <div>
              Сведения об ограничении права принимать участие в заключении договоров строительного подряда,
              договоров подряда на осуществление сноса объектов капитального строительства с использованием
              конкурентных способов заключения договоров
            </div>
          </div>
        </th>
        <th style="width: 130px; cursor: default">
          <div>
            <!--            <sort-icon :sort="options.sortBy.registry_registration_date"/>-->
            <div>
              Дата уплаты взноса (дополнительного взноса)
            </div>
          </div>
        </th>
      </tr>

      <tr style="cursor: default;"
      >
        <td>{{ right.right_status.title }}</td>
        <td>
          {{ right.basis }}
        </td>
        <td>{{ right.is_simple ? 'Да' : 'Нет' }}</td>
        <td>{{ right.is_extremely_dangerous ? 'Да' : 'Нет' }}</td>
        <td>{{ right.is_nuclear ? 'Да' : 'Нет' }}</td>
        <td>
          <div
            v-if="memberResponsibilityLevelVv"
          >
            {{ getResponsibilityLevelVV(memberResponsibilityLevelVv) }}
          </div>
          <div v-else style="text-align: center">
            -
          </div>
        </td>
        <td>
          <div
            v-if="memberResponsibilityLevelOdo"
          >
            {{ memberResponsibilityLevelOdo.title }}, {{ memberResponsibilityLevelOdo.cost }}
          </div>
          <div v-else style="text-align: center">
            -
          </div>
        </td>

        <td><div style="text-align: center">{{ getRightRestrictInfo(right) }}</div></td>

        <td>
          <div
            v-if="right.compensation_fund_fee_odopayment_date"
          >
          {{ formatDateShort(new Date(right.compensation_fund_fee_odopayment_date)) }}
          </div>
          <div v-else style="text-align: center">
            -
          </div>
        </td>
      </tr>
    </table>
    <!-- right actions -->

    <div v-if="right && right.actions.length"
      class="card__title"
      style="text-align: center;"
    >
      Решения в отношении права
      <div class="card-list">
        <div
          class="card"
          v-for="action in right.actions"
          :key="'action-' + action.id"
        >
          <div class="cart-item">
            <div class="card__title">
              Дата решения:
            </div>
            <div class="card__value">
              {{ formatDateShort(new Date(action.basis_date))}}
            </div>
            <div class="card__title">
              Основание решения:
            </div>
            <div class="card__value">
              {{ action.basis}}
            </div>
            <div class="card__title">
              Решение органов управления:
            </div>
            <div class="card__value">
              {{ action.suspension_decision.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table__list"
           v-if="right && right.actions.length"
    >
      <tr>
        <th style="cursor: default;">
          <div>
            <div>
              Дата решения
            </div>
          </div>
        </th>
        <th style="cursor: default;">
          <div style="justify-content: left;">
            Основание решения
          </div>
        </th>
        <th style="cursor: default;">
          <div>
            Решение органов управления
          </div>
        </th>
      </tr>
      <tr
        v-for="action in right.actions"
        :key="'action-' + action.id"
        style="cursor: default"
      >
      <td class="text-center">{{ formatDateShort(new Date(action.basis_date))}}</td>
      <td>{{ action.basis}}</td>
      <td class="text-center">{{ action.suspension_decision.short_title}}</td>
      </tr>
    </table>
    <div
      class="mt-3"
      v-if="!right">
      Сведения отсутствуют
    </div>
  </div>
</template>

<script>
import { formatDateShort } from '../../helpers/format'

export default {
  name: 'MemberRights',
  props: {
    right: {
      type: Object,
      default: null
    },
    memberResponsibilityLevelOdo: {
      type: Object,
      default: null
    },
    memberResponsibilityLevelVv: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatDateShort,
    getResponsibilityLevelVV (level) {
      if (!level) {
        return '-'
      }

      if (level.code === '0') {
        return level.title + ' (только снос)'
      }
      return level.title + ', ' + level.cost
    },
    getRightRestrictInfo (right) {
      const msgNotRestrict = 'Действует без ограничений, в пределах фактического совокупного размера обязательств'
      const msgRestrict = 'Ограничено'
      const msgEmpty = '-'

      if (right) {
        if (right.is_odo === false) {
          return msgEmpty
        }
        if (right.actions.length) {
          /*
          suspension_decision.code === '6' - restrict was removed
          suspension_decision.code === '5' - restrict was added
         */
          for (const i in right.actions) {
            if (right.actions[i].suspension_decision.code === '6') {
              return msgNotRestrict
            } else if (right.actions[i].suspension_decision.code === '5') {
              return msgRestrict
            }
          }
        }
        return msgNotRestrict
      }
      return msgEmpty
    }
  }
}
</script>

<style scoped>

</style>
