export const downloadFileFromArrayBuffer = (arrayBuffer, fileName, contentType) => {
  const link = document.createElement('a')
  const blob = new Blob([arrayBuffer], { type: contentType })
  const url = window.URL.createObjectURL(blob)
  link.href = url
  link.download = fileName
  link.click()
  window.URL.revokeObjectURL(url)
}

export const validateRequestData = data => {
  if (!data.success || !data.data) {
    if (data.message) {
      return {
        success: false,
        message: data.message
      }
    } else {
      return {
        success: false,
        message: 'Непредвиденная ошибка сервера'
      }
    }
  }
  return {
    success: true
  }
}
