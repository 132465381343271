<template>
  <div class="card-list">
    <div v-if="checks.length === 0">
      Проверки не проводились
    </div>
    <div
      class="card"
      :class="{'card-one': checks.length === 1}"
      v-for="checkItem in sortChecks"
      :key="'check-card-' + checkItem.id"
    >
      <div class="card-item">
        <div class="card-item__title">
          Тип проверки:
        </div>
        <div class="card-item__value">
          {{ checkItem.member_check_type.title }}
        </div>

        <div class="card-item__title">
          Дата окончания проверки:
        </div>
        <div class="card-item__value">
          {{ formatDateShort(new Date(checkItem.check_date)) }}
        </div>

        <div class="card-item__title">
          Результат проверки члена СРО:
        </div>
        <div class="card-item__value">
          {{ checkItem.member_check_result.title }}
        </div>

        <div class="card-item__title">
          Факты применения мер дисциплинарного воздействия:
        </div>
        <div class="card-item__value">
          {{ checkItem.disciplinary_action ? checkItem.disciplinary_action.title : '' }}
        </div>
      </div>
    </div>
    <table class="table__list">
      <tr>
        <th style="cursor: default;">
          <div>
            <!--            <sort-icon :sort="options.sortBy.sro_full_description"/>-->
            <div>Дата окончания проверки</div>
          </div>
        </th>
        <!--        <th @click="sort('member_status')">-->
        <th style="cursor: default;">
          <!--          <sort-icon :sort="options.sortBy.member_status"/>-->
          <div>
            Тип проверки
          </div>
        </th>

        <!--        <th @click="sort('full_description')">-->
        <th style="cursor: default;">
          <div>
            <!--            <sort-icon :sort="options.sortBy.full_description"/>-->
            <div>
              Результат проверки члена СРО
            </div>
          </div>
        </th>

        <!--        <th @click="sort('inn')">-->
        <th style="cursor: default;">
          <div>
            <!--            <sort-icon :sort="options.sortBy.inn"/>-->
            <div>
              Факты применения мер дисциплинарного воздействия
            </div>
          </div>
        </th>
      </tr>

      <tr
        v-for="checkItem in sortChecks"
        :key="'check-' + checkItem.id"
        style="cursor: default;"
      >
        <td style="text-align: center">{{ formatDateShort(new Date(checkItem.check_date)) }}</td>
        <td style="text-align: center">{{ checkItem.member_check_type.title }}</td>
        <td style="text-align: center">{{ checkItem.member_check_result.title }}</td>
        <td style="text-align: center">{{ checkItem.disciplinary_action ? checkItem.disciplinary_action.title : '' }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { formatDateShort } from '../../helpers/format'

export default {
  name: 'MemberChecks',
  props: {
    checks: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      sortChecks: this.checks.sort((a, b) => new Date(b.check_date) - new Date(a.check_date))
    }
  },
  methods: {
    formatDateShort
  }
}
</script>

<style scoped>

</style>
