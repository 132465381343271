<template>
  <div class="card-list">
    <div
      class="card"
      :class="{'card-one': insurances.length === 1}"
      v-for="insuranceItem in insurances"
      :key="'insurance-card-' + insuranceItem.id"
    >
      <div class="card-item"
           style="cursor: default;"
      >
        <div class="card-item__title">
          Предмет договора страхования:
        </div>
        <div class="card-item__value">
          {{ insuranceItem.object ? insuranceItem.object.short_title : ''}}
        </div>

        <div class="card-item__title">
          Начало / окончание действия договора:
        </div>
        <div class="card-item__value">
          {{ formatDateShort(insuranceItem.begin_date)}} /
          {{ formatDateShort(insuranceItem.end_date)}}
        </div>

        <div class="card-item__title">
          Размер страховой суммы:
        </div>
        <div class="card-item__value">
          {{ formatPrice(insuranceItem.insurance_summ) }}
        </div>

        <div class="card-item__title">
          Наименование страховой компании:
        </div>
        <div class="card-item__value">
          {{ insuranceItem.insurer }}
        </div>

        <div class="card-item__title">
          Лицензия:
        </div>
        <div class="card-item__value">
          {{ insuranceItem.license }}
        </div>

        <div class="card-item__title">
          Место нахождения:
        </div>
        <div class="card-item__value">
          {{ insuranceItem.place }}
        </div>

        <div class="card-item__title">
          Контактные телефоны:
        </div>
        <div class="card-item__value">
          {{ insuranceItem.phone }}
        </div>
      </div>
    </div>
    <table
      class="table__list"
    >
      <tr
        style="cursor: default;"
      >
<!--        <th @click="sort('sro_registration_number')">-->
<!--        <th>-->
<!--          <div>-->
<!--            <div>-->
<!--              Номер п/п-->
<!--            </div>-->
<!--          </div>-->
<!--        </th>-->
<!--        <th @click="sort('sro_full_description')">-->
        <th style="cursor: default;">
          <div>
<!--            <sort-icon :sort="options.sortBy.sro_full_description"/>-->
            <div>Предмет договора страхования</div>
          </div>
        </th>
        <th style="cursor: default;">
          Начало / окончание действия договора
        </th>
<!--        <th @click="sort('member_status')">-->
        <th style="cursor: default;">
<!--          <sort-icon :sort="options.sortBy.member_status"/>-->
          Размер страховой суммы
        </th>

<!--        <th @click="sort('full_description')">-->
        <th style="cursor: default;">
          <div>
<!--            <sort-icon :sort="options.sortBy.full_description"/>-->
            <div>Наименование страховой компании</div>
          </div>
        </th>

<!--        <th @click="sort('inn')">-->
        <th style="min-width: 100px; cursor: default">
          <div>
<!--            <sort-icon :sort="options.sortBy.inn"/>-->
            <div>
              Лицензия
            </div>
          </div>
        </th>

<!--        <th @click="sort('ogrnip')">-->
        <th style="cursor: default;">
          <div>
<!--            <sort-icon :sort="options.sortBy.ogrnip"/>-->
            <div>
              Место нахождения
            </div>
          </div>
        </th>

<!--        <th @click="sort('registry_registration_date')">-->
        <th style="width: 130px; cursor:default;">
          <div>
<!--            <sort-icon :sort="options.sortBy.registry_registration_date"/>-->
            <div>
              Контактные телефоны
            </div>
          </div>
        </th>
      </tr>

      <tr
        v-for="(insuranceItem) in insurances"
        :key="'insurance-' + insuranceItem.id"
        style="cursor: default;"
      >
<!--        <td style="text-align: center">{{ idx + 1 }}</td>-->
        <td>{{ insuranceItem.object ? insuranceItem.object.short_title : '' }}</td>
        <td>
          {{ formatDateShort(insuranceItem.begin_date)}} /
          {{ formatDateShort(insuranceItem.end_date)}}
        </td>
        <td style="text-align: center">{{ formatPrice(insuranceItem.insurance_summ) }}</td>
        <td>{{ insuranceItem.insurer }}</td>
        <td>{{ insuranceItem.license }}</td>
        <td>{{ insuranceItem.place }}</td>
        <td>{{ insuranceItem.phone }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { formatPrice, formatDateShort } from '../../helpers/format'

export default {
  name: 'MemberInsurances',
  props: {
    insurances: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatPrice,
    formatDateShort (dateTime) {
      if (!dateTime) return
      return formatDateShort(new Date(dateTime))
    }
  },
  created () {
    window.insurances = this
  }
}
</script>

<style scoped>

</style>
