<template>
  <div>
    <div class="breadcrumbs" v-if="member && !isFrame">
      <router-link :to="{ name: 'SroList' }" exact> Реестр СРО</router-link>
      /
      <router-link :to="{name: 'SroItem', params: { id: member.sro.id } }"> {{ member.sro.short_description }}</router-link>
      /
      <router-link :to="{name: 'MemberListBySro', params: { id: member.sro.id } }"> Реестр членов СРО </router-link>
      /
      <span> {{ member.short_description.replaceAll(' ', '&nbsp;') }} </span>
    </div>
    <warn-message
      v-if="warnMessage"
    > {{ warnMessage }} </warn-message>
    <error-message
      v-if="errorMessage"
    >{{ errorMessage }} </error-message>
    <spinner
      v-if="!member && !errorMessage && spinnerIsShow"
    />
    <div
      v-if="member"
    >
      <div class="page-title"
           v-if="member"
      > {{ member.full_description }}</div>
      <div class="buttons-item-download mb-2">
        <v-btn
          class="btn"
          color="secondary"
          @click="downloadCart"

        >
          <div class="d-flex justify-center align-center">
            <v-icon style="font-size: 20px">
              mdi-download
            </v-icon>
            <span>Скачать карточку</span>
          </div>
        </v-btn>
      </div>
      <v-expansion-panels
        class="member-card"
        :value="openPanel"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="filter__title">Общая информация</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <member-card-info :member="member" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="filter__title">Страхование</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <member-insurances :insurances="member.insurances" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="filter__title">Проверки</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <member-checks :checks="member.checks" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="filter__title">Сведения о наличии права</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <member-rights
              :right="member.right"
              :member-responsibility-level-vv="member.responsibility_level_vv"
              :member-responsibility-level-odo="member.responsibility_level_odo"
              />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="filter__title">Информация о КФ</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <member-funds-info
              :compensation-fund-fee-odo="+member.compensation_fund_fee_odo"
              :compensation-fund-fee-vv="+member.compensation_fund_fee_vv"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="filter__title">Обязательство по договорам</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <member-liability
              :members-total-liability="member.members_total_liability"
              :members-total-liability-last-count-dt="member.members_total_liability_last_count_dt_date_string"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card
        class="tabs-item"
      >
        <v-tabs
          v-model="tab"
          :center-active="true"
          active-class="max-width"
          grow
        >
          <v-tab
            :key="1"
          >
            Общая информация
          </v-tab>
          <v-tab
            :key="2"
          >
            Страхование
          </v-tab>
          <v-tab
            :key="3"
          >
            Проверки
          </v-tab>
          <v-tab
            :key="4"
          >
            Сведения о наличии права
          </v-tab>
          <v-tab
            :key="5"
          >
            Информация о КФ
          </v-tab>
          <v-tab
            :key="6"
          >
            Обязательства по договорам
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
        >
          <v-tab-item
            :key="1"
          >
            <div class="tab-item">
              <member-card-info :member="member"/>
            </div>
          </v-tab-item>
          <v-tab-item
            :key="2"
          >
            <div
              class="tab-item"
            >
              <member-insurances :insurances="member.insurances" />
            </div>
          </v-tab-item>
          <v-tab-item
            :key="3"
          >
            <div class="tab-item">
              <member-checks :checks="member.checks" />
            </div>
          </v-tab-item>
          <v-tab-item
            :key="4"
          >
            <div class="tab-item">
              <member-rights
                :right="member.right"
                :member-responsibility-level-vv="member.responsibility_level_vv"
                :member-responsibility-level-odo="member.responsibility_level_odo"
              />
            </div>
          </v-tab-item>
          <v-tab-item
            :key="5"
          >
            <div class="tab-item">
              <member-funds-info
                :compensation-fund-fee-odo="+member.compensation_fund_fee_odo"
                :compensation-fund-fee-vv="+member.compensation_fund_fee_vv"
              />
            </div>
          </v-tab-item>
          <v-tab-item
            :key="6"
          >
            <div class="tab-item">
              <member-liability
                :members-total-liability="member.members_total_liability"
                :members-total-liability-last-count-dt="member.members_total_liability_last_count_dt_date_string"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import { downloadCartMember, downloadStatementMember, getMember, getMemberFrame } from '../../helpers/api'
import MemberCardInfo from '../../components/member/MemberCardInfo'
import ErrorMessage from '../../components/alerts/ErrorMessage'
import WarnMessage from '../../components/alerts/WarnMessage'
import Spinner from '../../components/spinner/Spinner'
import MemberInsurances from '../../components/member/MemberInsurances'
import MemberChecks from '../../components/member/MemberChecks'
import MemberRights from '../../components/member/MemberRights'
import MemberFundsInfo from '../../components/member/MemberFundsInfo'
import MemberLiability from '../../components/member/MemberLiability'
import { downloadFileFromArrayBuffer } from '../../helpers/helper'
import { STATEMENT_DISABLED, STATEMENT_DISABLED_MESSAGE } from '../../helpers/constants'

export default {
  name: 'MemberItem',
  components: {
    MemberLiability,
    MemberFundsInfo,
    MemberRights,
    MemberChecks,
    MemberInsurances,
    ErrorMessage,
    WarnMessage,
    Spinner,
    MemberCardInfo
  },
  STATEMENT_DISABLED,
  data () {
    return {
      member: null,
      openPanel: 0,
      tab: null,
      errorMessage: '',
      warnMessage: STATEMENT_DISABLED ? STATEMENT_DISABLED_MESSAGE : '',
      spinnerIsShow: false,
      statementSpinnerIsShow: false,
      isFrame: this.$route.name === 'FrameMemberItem'
    }
  },
  methods: {
    async getMember () {
      this.spinnerIsShow = true
      let requestData
      if (this.isFrame) {
        requestData = await getMemberFrame(this.$route.params.id)
      } else {
        requestData = await getMember(this.$route.params.id)
      }

      this.spinnerIsShow = false

      if (!requestData.success) {
        if (!requestData.message) {
          this.errorMessage = 'Непредвиденная ошибка сервера'
        } else {
          this.errorMessage = requestData.message
        }
        return
      }

      this.member = requestData.data
    },
    async downloadCart () {
      const responseData = await downloadCartMember(this.member.id)
      if (!responseData.success) {
        this.errorMessage = responseData.message
        return
      }
      let filename = this.member.short_description.replaceAll('.', '_')
      filename = filename.replace(/_$/, '')
      downloadFileFromArrayBuffer(responseData.data, `Карточка_${filename}`, responseData.contentType)
      this.errorMessage = ''
    },
    async downloadStatement () {
      this.statementSpinnerIsShow = true
      const responseData = await downloadStatementMember(this.member.id)
      if (!responseData.success) {
        this.errorMessage = responseData.message
        this.statementSpinnerIsShow = false
        return
      }

      this.statementSpinnerIsShow = false
      let filename = this.member.short_description.replace('.', '_')
      filename = filename.replace(/_$/, '')
      downloadFileFromArrayBuffer(responseData.data, `Выписка_${filename}`, responseData.contentType)
      this.errorMessage = ''
    }
  },
  created () {
    this.getMember()
  }
}
</script>

<style scoped>

</style>
