<template>
  <div class="card-list">
    <div class="display-normal-none">
      <div
        class="card-item"
      >
        <div class="card__title">
          Фактический совокупный размер обязательств (в рублях):
          <span
            class="card__value"
            v-if="membersTotalLiability"
          >
              {{ formatPrice(formatStringAsNumber(membersTotalLiability)) }}
            </span>
          <span
            class="card__value"
            v-else
          >-</span>
        </div>

        <div class="card__title">
          Дата последнего расчета совокупного размера обязательств:
        </div>
        <div
          class="card__value"
          v-if="membersTotalLiabilityLastCountDt"
        >
          {{ membersTotalLiabilityLastCountDt }}
        </div>
        <div
          class="card__value"
          v-else
        >
          -
        </div>
      </div>
    </div>

    <table class="display-mobile-none">
      <tr>
        <td
            class="border-t px-10"
        >
          <div class="card__title">
            Фактический совокупный размер обязательств (в рублях):
            <span
              class="card__value"
              v-if="membersTotalLiability"
            >
              {{ formatPrice(formatStringAsNumber(membersTotalLiability)) }}
            </span>
            <span
              class="card__value"
              v-else
            >-</span>
          </div>
        </td>
      </tr>
      <tr>
        <td
          class="px-10 border-tb"
        >
          <div class="card__title">
            Дата последнего расчета совокупного размера обязательств:
            <span
              class="card__value"
              v-if="membersTotalLiabilityLastCountDt"
            >
              {{ membersTotalLiabilityLastCountDt }}
            </span>
            <span
              class="card__value"
              v-else
            >-</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { formatPrice, formatStringAsNumber } from '../../helpers/format'

export default {
  name: 'MemberLiability',
  props: {
    membersTotalLiability: {
      type: [String, Number]
    },
    membersTotalLiabilityLastCountDt: {
      type: [String, Number]
    }
  },
  methods: {
    formatPrice,
    formatStringAsNumber
  }
}
</script>

<style scoped>

</style>
